import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import toast from "react-hot-toast";
import { backendApi } from "../../api";
import { getUserById } from "../services/users.services";
import { useParams } from "react-router-dom";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export const updateStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-35%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius : "10px",
    p: 4,
};


export default function CreateSchedule({student, modalOpen, handleModalClose, setStudent}) {
  const [info, setInfo] = useState({
    seance : "",
    status : "",
    date : new Date()
  })
  const [loading, setloading] = useState(false);
  const { userId } = useParams();

  const createScheduleEvent  = async () => {
    try {
      setloading(true);
      const res  = await backendApi.post(`/user/schedules/${student._id}`, info);
      if (res.status == 201) {
        toast.success("Absence étudiant créée avec succès");
        const response = await getUserById(userId);
        setStudent(response);
        setloading(false);
        handleModalClose();
      }
    } catch (err) {
      setloading(false);
      toast.error("faild to create a schedule");
      console.log(err);
    }
  }
  return (
    <Modal
      keepMounted
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={updateStyle}>
        <h2>Créer une nouvelle absence {student?.fullname}</h2>
        <Box marginTop={2} display={"flex"} alignItems={"flex-start"}>
          <Box flex={1}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <label htmlFor="title">Séance</label>
              <TextField
                id="seance"
                variant="outlined"
                label="Séance"
                value={info.seance}
                onChange={(e) => setInfo({ ...info, seance: e.target.value })}
              />
            </Box>
            <Box 
              marginTop={2}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <label htmlFor="date">La Date</label>
              <TextField
                id="schedule"
                type="date"
                variant="outlined"
                placeholder="Date"
                value={info.date}
                onChange={(e) =>
                  setInfo({ ...info, date: e.target.value })
                }
                flex={1}
                fullWidth
              />
            </Box>
            <Box
              marginTop={2}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <label htmlFor="duration">Status</label>
              <Select
                id="status"
                variant="outlined"
                label="Status"
                onChange={(e) => setInfo({ ...info, status: e.target.value })}
                value={info.status}
                fullWidth={true}
                input={<OutlinedInput label="Status" />}
                MenuProps={MenuProps}
              >
                <MenuItem value="Présent"> Présent </MenuItem>
                <MenuItem value="Absent"> Absent </MenuItem>
              </Select>
            </Box>
            {loading ? (
              <Button
                variant="contained"
                disabled
                style={{ marginTop: "20px" }}
                fullWidth
              >
                <CircularProgress />
              </Button>
            ) : (
              <Button
                onClick={createScheduleEvent}
                variant="contained"
                style={{ marginTop: "20px" }}
                fullWidth
              >
                Enregistrer
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
