import { Box, Table, TableContainer, TableHead, TableRow, Paper, TableBody, Button  } from '@mui/material';
import React, {useState, useEffect} from 'react'
import { StyledTableCell, StyledTableRow } from '../pages/manage/students/Profile';
import EditIcon from '@mui/icons-material/Edit';
import dateFormat, {masks} from 'dateformat';
import CreateSchedule from './CreateSchedule';
export default function Schedule({student, style, setStudent}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  }
  return (
    <Box style={style}>
      <h2 className="text-3xl mb-2"> Absence Table </h2>
      <TableContainer
        component={Paper}
        style={{
          marginTop: "20px",
        }}
      >
        {isOpen && (
          <CreateSchedule 
            student={student} 
            handleModalClose={handleClose} 
            modalOpen={isOpen}  
            setStudent={setStudent}
          />
        )}
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell>Payment ID</StyledTableCell> */}
              <StyledTableCell align="left">Nomber Séance</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">La Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {student?.schedules.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell
                  style={{
                    padding: "20px",
                    color: "grey",
                    backgroundColor: "white",
                  }}
                >
                  {student?.fullname} Pas encore effectué de Absent
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              student?.schedules?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.seance}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dateFormat(row.date, masks["mediumDate"])}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={() => setIsOpen(true)}
        variant="contained"
        color="primary"
        style={{
          marginTop: 20
        }}
      >
        Effectuer un Absence
      </Button>
    </Box>
  );
}